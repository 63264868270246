import { t } from 'i18next';
import { CityType } from '../../../types/cityType';
import { Address } from './types/Address';
import { OpeningHours } from './types/OpeningHours';

export const getAddress = (city: CityType): Address => {
    switch(city) {
        case CityType.Komarno: {
            return {
                street: 'Františkánov 14',
                city: '945 01 Komárno',
                tel: '0908/10 10 18',
            };
        }
        case CityType.Kolarovo: {
            return {
                street: 'Rábska 9',
                city: '946 03, Kolárovo ',
                tel: '0948/422 100',
            };
        }
        case CityType.NoveZamky: {
            return {
                street: 'Ernestova bašta 10',
                city: '940 02 Nové Zámky',
                tel: '0948/42 01 20',
            };
        }
    }
};

export const getOpeningHours = (city: CityType): OpeningHours => {
    let openingHours: OpeningHours;

    if (city === CityType.NoveZamky || city === CityType.Komarno) {
        openingHours = {
            FromMondayToSaturday: '10:00 - 22:00',
            Sunday: '14:00 - 22:00',
        };
    } else {
        openingHours = {
            FromMondayToSaturday: '10:00 - 22:00',
            Sunday: t('Zatvorene'),
        };
    }

    return openingHours;
};

export const getMapsSource = (city: CityType) => {
    switch (city) {
        case CityType.NoveZamky: {
            return 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d635.0374793576785!2d18.164367031365824!3d47.989047805143386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476b06ac7d21a575%3A0x43f09a6ca480f1a1!2zRHIgUGl6emEgTm92w6kgWsOhbWt5!5e0!3m2!1sen!2ssk!4v1681847284938!5m2!1sen!2ssk';
        }
        case CityType.Kolarovo: {
            return 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1178.0084103029808!2d17.993508486246!3d47.917471885030785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476ba7dc4b6fea17%3A0x38a9dd99231b4b35!2sDr.%20Pizza!5e0!3m2!1sen!2ssk!4v1681847109115!5m2!1sen!2ssk';
        }
        case CityType.Komarno: {
            return 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2682.1683843110127!2d18.130873919451563!3d47.7587881775222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476bad54f52d8aa1%3A0x6eb268e3fe62ee3d!2zRnJhbnRpxaFrw6Fub3YgMTQsIDk0NSAwMSBLb23DoXJubw!5e0!3m2!1sen!2ssk!4v1698786208487!5m2!1sen!2ssk';
        }
    }
};
